import React, { FunctionComponent, useMemo } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import PaperContainer from '../components/PaperContainer';
import { useDeviceOverviewQuery, useVersionsQuery } from '../api/hooks';
import { useTranslation } from 'react-i18next';

export const SystemInformationPage: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const { isPending: deviceOverviewPending, data: deviceOverview } = useDeviceOverviewQuery();
    const { isPending: versionsPending, data: versions } = useVersionsQuery();

    const rows = useMemo(() => {
        const rows = [];

        if (versions) {
            rows.push({
                title: t('ntuity-os-version'),
                value: versions.currentNtuityOs,
            });
        }

        if (deviceOverview) {
            rows.push({
                title: t('serial-number'),
                value: deviceOverview.serialNumber,
            });
        }

        return rows;
    }, [versions, deviceOverview, t]);

    if (versionsPending || deviceOverviewPending) {
        return (
            <PaperContainer>
                <CircularProgress />
            </PaperContainer>
        );
    }

    return (
        <PaperContainer>
            <Grid container direction="column">
                {rows.length > 0 ? (
                    rows.map((row, i) => {
                        return (
                            <Grid item key={`system_information_row_${i}`} style={{ padding: '0.5rem' }}>
                                <Typography variant="h5" gutterBottom sx={{ userSelect: 'none' }}>
                                    {row.title}
                                </Typography>
                                <Typography variant="body1" sx={{ userSelect: 'all' }}>
                                    {row.value}
                                </Typography>
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
        </PaperContainer>
    );
};
