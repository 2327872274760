import { Chip } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ReactComponent as CheckSuccessIcon } from '../assets/check.svg';
import { ReactComponent as CheckFailureIcon } from '../assets/check-warning.svg';
import { ReactComponent as CheckUnknownIcon } from '../assets/check-in-progess.svg';
import { ConnectivityCheckCheckResult } from '../api/types';
import { InfoDialog } from './InfoDialog';
import { useTranslation } from 'react-i18next';

export enum ConnectivityCheckCategoryState {
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
    UNKNOWN = 'unknown',
}

const stateColors = {
    [ConnectivityCheckCategoryState.UNKNOWN]: {
        foreground: '#EC7100',
        background: '#ffdbba',
    },
    [ConnectivityCheckCategoryState.SUCCEEDED]: {
        foreground: '#506526',
        background: '#dae9be',
    },
    [ConnectivityCheckCategoryState.FAILED]: {
        foreground: '#9C0000',
        background: '#F8D7DA',
    },
};

const stateIcons = {
    [ConnectivityCheckCategoryState.UNKNOWN]: <CheckUnknownIcon />,
    [ConnectivityCheckCategoryState.SUCCEEDED]: <CheckSuccessIcon />,
    [ConnectivityCheckCategoryState.FAILED]: <CheckFailureIcon />,
};

export const ConnectivityCheckChip: FunctionComponent<{
    type: string;
    label: string;
    state: ConnectivityCheckCategoryState;
    checks: Array<ConnectivityCheckCheckResult>;
}> = ({ type, label, state, checks }): React.ReactElement | null => {
    const { t } = useTranslation();
    const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);

    return (
        <>
            <Chip
                label={
                    <>
                        {stateIcons[state]} {label}
                    </>
                }
                size={'small'}
                sx={{
                    color: stateColors[state].foreground,
                    backgroundColor: stateColors[state].background,
                    userSelect: 'none',
                }}
                onClick={
                    state === ConnectivityCheckCategoryState.FAILED
                        ? () => {
                              setInfoDialogOpen(true);
                          }
                        : undefined
                }
            />
            <InfoDialog
                title={t(`connectivity-check-error-title-${type}`)}
                body={
                    <>
                        {t(`connectivity-check-error-desc1-${type}`)}

                        {type !== 'SSH' ? (
                            <ul>
                                {checks
                                    .filter((c) => !c.succeeded)
                                    .map((check, i) => {
                                        return <li key={`${type}_${i}`}>{check.description}</li>;
                                    })}
                            </ul>
                        ) : (
                            <>
                                <br />
                                <br />
                            </>
                        )}

                        {t(`connectivity-check-error-desc2-${type}`)}
                    </>
                }
                dialogOpen={infoDialogOpen}
                setDialogOpen={(open) => setInfoDialogOpen(open)}
            />
        </>
    );
};
