import React, { FunctionComponent } from 'react';
import { ConnectivityCheckView } from '../views/ConnectivityCheckView';
import { SpeedtestView } from '../views/SpeedtestView';

export const StatusPage: FunctionComponent<{}> = (): React.ReactElement | null => {
    return (
        <>
            <ConnectivityCheckView />
            <br />
            <SpeedtestView />
        </>
    );
};
