import React, { FunctionComponent } from 'react';
import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';
import { useConnectivityCheckSpeedtestQuery, useTriggerConnectivityCheckSpeedtestMutation } from '../api/hooks';
import { ReactComponent as SpeedtestIcon } from '../assets/speedtest-icon.svg';
import { ReactComponent as ChecksLoadingIcon } from '../assets/loading-icon.svg';
import { ReactComponent as ChecksSuccessIcon } from '../assets/success-icon.svg';
import { ReactComponent as ChecksFailureIcon } from '../assets/failure-icon.svg';
import { ReactComponent as ChecksWarningIcon } from '../assets/warning-icon.svg';
import { useTranslation } from 'react-i18next';
import { ActionFeedbackButton } from '../components/ActionFeedbackButton';

export const SpeedtestView: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const { isPending: speedtestResultPending, data: speedtestResult } = useConnectivityCheckSpeedtestQuery();
    const { mutate: triggerSpeedtest, isPending: triggerSpeedtestExecuting } =
        useTriggerConnectivityCheckSpeedtestMutation();

    const SpeedtestResultIcon = React.useMemo(() => {
        if (!speedtestResult) {
            return <ChecksLoadingIcon />;
        }

        switch (speedtestResult.state) {
            case 'unknown':
                return <ChecksWarningIcon />;
            case 'active':
                return <ChecksLoadingIcon />;
            case 'finished':
                if (speedtestResult.succeeded) {
                    return <ChecksSuccessIcon />;
                } else {
                    return <ChecksFailureIcon />;
                }
        }
    }, [speedtestResult]);

    const SpeedtestResultTimestamp = React.useMemo(() => {
        if (speedtestResult?.timestamp) {
            const timestampDate = new Date(speedtestResult.timestamp * 1000);

            return `${timestampDate.toLocaleDateString()} - ${timestampDate.toLocaleTimeString()}`;
        } else {
            return '';
        }
    }, [speedtestResult]);

    const SpeedtestResultDetails = React.useMemo(() => {
        const res = {
            ping: '',
            upload: '',
            download: '',
        };

        if (speedtestResult) {
            if (speedtestResult.checks['PING']?.checks?.[0]) {
                res.ping = `${speedtestResult.checks['PING'].checks[0].description} ms`;
            }

            if (speedtestResult.checks['UPLOAD']?.checks?.[0]) {
                res.upload = `${speedtestResult.checks['UPLOAD'].checks[0].description} Mbit/s`;
            }

            if (speedtestResult.checks['DOWNLOAD']?.checks?.[0]) {
                res.download = `${speedtestResult.checks['DOWNLOAD'].checks[0].description} Mbit/s`;
            }
        }

        return res;
    }, [speedtestResult]);

    return (
        <Container style={{ padding: 0 }}>
            <Card variant="outlined">
                <Box sx={{ p: 2, paddingBottom: 1, paddingTop: 1 }}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" direction="row">
                                <Grid item>
                                    <SpeedtestIcon />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        style={{
                                            fontWeight: 700,
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                            userSelect: 'none',
                                        }}
                                    >
                                        {t('speedtest')}
                                    </Typography>
                                </Grid>
                                <Grid item>{SpeedtestResultIcon}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography color="text.secondary" variant="body2" sx={{ userSelect: 'none' }}>
                                {t('last-checked')}:{' '}
                                <span style={{ fontWeight: 'bolder', userSelect: 'all' }}>
                                    {SpeedtestResultTimestamp}
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />

                <Grid container direction="row">
                    <Grid item container direction="column" xs={4} sx={{ p: 2 }}>
                        <Grid item>
                            <Typography variant="body1" sx={{ userSelect: 'none' }}>
                                {t('ping')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="text.secondary" variant="subtitle1" sx={{ userSelect: 'all' }}>
                                {SpeedtestResultDetails.ping}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                    <Grid item container direction="column" xs={4} sx={{ p: 2 }}>
                        <Grid item>
                            <Typography variant="body1" sx={{ userSelect: 'none' }}>
                                {t('upload')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="text.secondary" variant="subtitle1" sx={{ userSelect: 'all' }}>
                                {SpeedtestResultDetails.upload}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                    <Grid item container direction="column" xs={4} sx={{ p: 2 }}>
                        <Grid item>
                            <Typography variant="body1" sx={{ userSelect: 'none' }}>
                                {t('download')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="text.secondary" variant="subtitle1" sx={{ userSelect: 'all' }}>
                                {SpeedtestResultDetails.download}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />

                <div
                    style={{
                        marginTop: '0.5rem',
                        padding: '1rem',
                    }}
                >
                    <ActionFeedbackButton
                        label={speedtestResult?.state === 'active' ? t('run-speedtest-progress') : t('run-speedtest')}
                        actionActive={speedtestResult?.state === 'active' || triggerSpeedtestExecuting}
                        disabled={
                            speedtestResultPending || speedtestResult?.state === 'active' || triggerSpeedtestExecuting
                        }
                        onClick={() => {
                            triggerSpeedtest();
                        }}
                    />
                </div>
            </Card>
        </Container>
    );
};
