import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import i18n from './i18n';
import App from './App';
import { LOCAL_STORAGE } from './const';
import * as Sentry from '@sentry/react';
import { fetchDevice } from './api/client';

import '@fontsource/titillium-web';

fetchDevice()
    .then(({ overview, env, versions }) => {
        Sentry.init({
            dsn: env.sentryDsn,
            tracesSampleRate: 1.0,
            environment: env.environment,
            release: env.sentryRelease,
            integrations: [Sentry.dedupeIntegration()],
        });
        Sentry.setTags({
            server_name: overview.hostname,
            gateway_UUID: overview.uuid,
            hardware_id: overview.hardwareIdentifier,
            api_endpoint: env.apiEndpoint,
            desired_OS_version: versions.desiredNtuityOs,
            current_OS_version: versions.currentNtuityOs,
            local_config_server: versions.localConfigServer,
            firmware: versions.firmware,
        });
    })
    .catch((e) => {
        console.error(`error initializing sentry: ${e}`);
    });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18n.changeLanguage(localStorage.getItem(LOCAL_STORAGE.LANG) ?? 'de')
    .then(() => {
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>,
        );
    })
    .catch((e) => {
        console.error(e);
    });
