import { MutationFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    fetchAvailableNetworkInterfaces,
    fetchBridgeDHCPLeases,
    fetchConnectivityCheckConnectivityResult,
    fetchConnectivityCheckSpeedtestResult,
    fetchDeviceOverview,
    fetchNetworkConfig,
    fetchNetworkState,
    fetchVersions,
    sendConnectivityCheckConnectivityTrigger,
    sendConnectivityCheckSpeedtestTrigger,
    sendNetworkConfig,
} from './client';
import { useSnackbar } from 'notistack';
import React from 'react';
import { NetworkConfig } from './types';

enum QueryKey {
    DeviceOverview = 'device_overview',
    Versions = 'versions',
    AvailableNetworkInterfaces = 'available_network_interfaces',
    NetworkConfig = 'network_config',
    NetworkState = 'network_state',
    BridgeDHCPLeases = 'bridge_dhcp_leases',
    ConnectivityCheckConnectivity = 'connectivity_check_connectivity',
    ConnectivityCheckSpeedtest = 'connectivity_check_speedtest',
}

const useErrorToast = (): ((error: unknown) => void) => {
    const { enqueueSnackbar } = useSnackbar();

    return React.useCallback(
        (error: any) => {
            enqueueSnackbar(error?.message ?? 'An error occurred', {
                variant: 'error',
            });
        },
        [enqueueSnackbar],
    );
};

const useFetchingMutation = <TData, TVariables>(options: {
    onError: (error: unknown) => void;
    queryKey: Array<QueryKey>;
    mutationFn: MutationFunction<TData, TVariables>;
}) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: options.mutationFn,
        onSuccess: (data) => {
            queryClient.setQueryData<TData>(options.queryKey, data, {
                updatedAt: Date.now(),
            });
        },
        onError: options.onError,
    });
};

export const useDeviceOverviewQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DeviceOverview],
        queryFn: fetchDeviceOverview,

        staleTime: Infinity,
    });
};

export const useVersionsQuery = () => {
    return useQuery({
        queryKey: [QueryKey.Versions],
        queryFn: fetchVersions,
    });
};

export const useAvailableNetworkInterfacesQuery = () => {
    return useQuery({
        queryKey: [QueryKey.AvailableNetworkInterfaces],
        queryFn: fetchAvailableNetworkInterfaces,

        //TODO: should it be infinity or should we regularly refresh this to catch an lte stick being attached?
        staleTime: Infinity,
    });
};

export const useNetworkConfigQuery = () => {
    return useQuery({
        queryKey: [QueryKey.NetworkConfig],
        queryFn: fetchNetworkConfig,
    });
};

export const useNetworkConfigMutation = () => {
    return useFetchingMutation({
        queryKey: [QueryKey.NetworkConfig],
        mutationFn: (networkConfig: NetworkConfig) => {
            return sendNetworkConfig(networkConfig).then(fetchNetworkConfig);
        },
        onError: useErrorToast(),
    });
};

export const useNetworkStateQuery = () => {
    return useQuery({
        queryKey: [QueryKey.NetworkState],
        queryFn: fetchNetworkState,
        staleTime: 5_000,
        refetchInterval: 5_000,
    });
};

export const useBridgeDHCPLeaseQuery = () => {
    return useQuery({
        queryKey: [QueryKey.BridgeDHCPLeases],
        queryFn: fetchBridgeDHCPLeases,
        staleTime: 5_000,
        refetchInterval: 5_000,
    });
};

export const useConnectivityCheckConnectivityQuery = () => {
    return useQuery({
        queryKey: [QueryKey.ConnectivityCheckConnectivity],
        queryFn: fetchConnectivityCheckConnectivityResult,
        staleTime: 5_000,
        refetchInterval: 5_000,
    });
};

export const useTriggerConnectivityCheckConnectivityMutation = () => {
    const { refetch: refetchConnectivityCheckConnectivity } = useConnectivityCheckConnectivityQuery();

    return useMutation({
        mutationFn: sendConnectivityCheckConnectivityTrigger,
        onError: useErrorToast(),
        onSuccess: () => {
            setTimeout(() => {
                refetchConnectivityCheckConnectivity().catch(() => {
                    /* intentional */
                });
            }, 500);
            setTimeout(() => {
                refetchConnectivityCheckConnectivity().catch(() => {
                    /* intentional */
                });
            }, 2500);
        },
    });
};

export const useConnectivityCheckSpeedtestQuery = () => {
    return useQuery({
        queryKey: [QueryKey.ConnectivityCheckSpeedtest],
        queryFn: fetchConnectivityCheckSpeedtestResult,
        staleTime: 5_000,
        refetchInterval: 5_000,
    });
};

export const useTriggerConnectivityCheckSpeedtestMutation = () => {
    const { refetch: refetchConnectivityCheckSpeedtest } = useConnectivityCheckSpeedtestQuery();

    return useMutation({
        mutationFn: sendConnectivityCheckSpeedtestTrigger,
        onError: useErrorToast(),
        onSuccess: () => {
            setTimeout(() => {
                refetchConnectivityCheckSpeedtest().catch(() => {
                    /* intentional */
                });
            }, 500);
            setTimeout(() => {
                refetchConnectivityCheckSpeedtest().catch(() => {
                    /* intentional */
                });
            }, 2500);
        },
    });
};
