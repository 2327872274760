import React, { FunctionComponent } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AvailableNetworkInterfaces, NetworkConfig } from '../../api/types';
import { IPv4_netmask_regex, IPv4_regex } from '../../util';
import { ConfigEditorField, getControlForField, SaveButton } from './formUtils';
import { NetworkIfaceStateCard } from '../../components/NetworkIfaceStateCard';

export const WanInterfaceSettings: FunctionComponent<{
    iface: string;
    availableNetworkInterfaces: AvailableNetworkInterfaces;
    config: NetworkConfig;
    configModified: boolean;
    updateConfig: (newConfig: NetworkConfig) => void;
    saveConfig: (config?: NetworkConfig) => void;
    configSaving: boolean;
}> = ({
    iface,
    availableNetworkInterfaces,
    config,
    configModified,
    updateConfig,
    saveConfig,
    configSaving,
}): React.ReactElement | null => {
    const { t } = useTranslation();
    const cloneConfig = React.useMemo(() => {
        return structuredClone(config);
    }, [config]);
    const [showBridgeConfig, setShowBridgeConfig] = React.useState<boolean>(!!config.internet_bridge.members[iface]);
    const [internetBridgeAliasIp, setInternetBridgeAliasIp] = React.useState<string>(
        config.internet_bridge.members[iface]?.alias ?? '',
    );

    const staticIpFields: Array<ConfigEditorField> = React.useMemo(() => {
        return [
            {
                type: 'input',
                label: t('ip-address'),
                getValue: () => {
                    return config.interfaces[iface].ip_config.address;
                },
                setValue: (val: string) => {
                    cloneConfig.interfaces[iface].ip_config.address = val;

                    updateConfig(cloneConfig);
                },
                isValid: () => {
                    return !!IPv4_regex.exec(config.interfaces[iface].ip_config.address);
                },
                invalidMsg: config.interfaces[iface].ip_config.address === '' ? t('missing-ip') : t('invalid-input'),
            },
            {
                type: 'input',
                label: t('netmask'),
                disabled: false,
                getValue: () => {
                    return config.interfaces[iface].ip_config.netmask;
                },
                setValue: (val: string) => {
                    cloneConfig.interfaces[iface].ip_config.netmask = val;

                    updateConfig(cloneConfig);
                },
                isValid: () => {
                    return !!IPv4_netmask_regex.exec(config.interfaces[iface].ip_config.netmask);
                },
                invalidMsg: config.interfaces[iface].ip_config.netmask === '' ? t('missing-ip') : t('invalid-input'),
            },
            {
                type: 'input',
                label: t('default-gw'),
                getValue: () => {
                    return config.interfaces[iface].ip_config.gateway;
                },
                setValue: (val: string) => {
                    cloneConfig.interfaces[iface].ip_config.gateway = val;

                    updateConfig(cloneConfig);
                },
                isValid: () => {
                    return !!IPv4_regex.exec(config.interfaces[iface].ip_config.gateway);
                },
                invalidMsg: config.interfaces[iface].ip_config.gateway === '' ? t('missing-ip') : t('invalid-input'),
            },
        ].map((e) => {
            e.disabled = e.disabled || configSaving;

            return e;
        }) as Array<ConfigEditorField>;
    }, [t, cloneConfig, updateConfig, config, configSaving, iface]);

    const dnsFields: Array<ConfigEditorField> = React.useMemo(() => {
        return [
            {
                type: 'input',
                disabled: false,
                label: t('primary-dns'),
                getValue: () => {
                    return config.interfaces[iface].dns.primary;
                },
                setValue: (val: string) => {
                    cloneConfig.interfaces[iface].dns.primary = val;

                    updateConfig(cloneConfig);
                },
                isValid: () => {
                    const val = config.interfaces[iface].dns.primary;

                    if (val === '' && config.interfaces[iface].mode === 'automatic') {
                        return true;
                    } else {
                        return !!IPv4_regex.exec(val);
                    }
                },
                invalidMsg: config.interfaces[iface].dns.primary === '' ? t('missing-ip') : t('invalid-input'),
            },
            {
                type: 'input',
                disabled: false,
                label: t('secondary-dns'),
                getValue: () => {
                    return config.interfaces[iface].dns.secondary;
                },
                setValue: (val: string) => {
                    cloneConfig.interfaces[iface].dns.secondary = val;

                    updateConfig(cloneConfig);
                },
                isValid: () => {
                    const val = config.interfaces[iface].dns.secondary;

                    return val === '' || !!IPv4_regex.exec(val);
                },
                invalidMsg: t('invalid-input'),
            },
        ].map((e) => {
            e.disabled = e.disabled || configSaving;

            return e;
        }) as Array<ConfigEditorField>;
    }, [t, cloneConfig, updateConfig, config, configSaving, iface]);

    const internetBridgeAliasField = React.useMemo(() => {
        return {
            type: 'input',
            disabled: configSaving,
            label: t('internet-bridge-alias-ip-address'),
            getValue: () => {
                return internetBridgeAliasIp;
            },
            setValue: (val: string) => {
                setInternetBridgeAliasIp(val);

                updateConfig(cloneConfig); //Called here to set the "configChanged" flag
            },
            isValid: () => {
                return !!IPv4_regex.exec(internetBridgeAliasIp);
            },
            invalidMsg: internetBridgeAliasIp === '' ? t('missing-ip') : t('invalid-input'),
        } as ConfigEditorField;
    }, [t, cloneConfig, updateConfig, configSaving, internetBridgeAliasIp]);

    const configValid = React.useMemo(() => {
        const staticIpFieldsValid = staticIpFields.reduce((a, c) => {
            return a && (c.isValid?.() ?? true);
        }, true);
        const dnsFieldsValid = dnsFields.reduce((a, c) => {
            return a && (c.isValid?.() ?? true);
        }, true);

        let out = true;

        out = out && dnsFieldsValid;
        if (config.interfaces[iface].mode === 'static') {
            out = out && staticIpFieldsValid;
        }
        if (showBridgeConfig) {
            out = out && internetBridgeAliasField.isValid!();
        }

        return out;
    }, [staticIpFields, dnsFields, config, internetBridgeAliasField, showBridgeConfig, iface]);

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                maxWidth: '550px',
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <Grid item sx={{ width: '100%' }}>
                <NetworkIfaceStateCard iface={iface} />
            </Grid>

            <Grid item sx={{ marginBottom: '1.5rem', width: '100%', userSelect: 'none' }}>
                <RadioGroup
                    value={config.interfaces[iface].mode}
                    onChange={(e) => {
                        cloneConfig.interfaces[iface].mode = e.target.value;

                        updateConfig(cloneConfig);
                    }}
                >
                    <FormControlLabel value="automatic" control={<Radio />} label={t('dynamic-ip')} />
                    <FormControlLabel value="static" control={<Radio />} label={t('static-ip')} />
                </RadioGroup>
            </Grid>

            {config.interfaces[iface].mode === 'static' && (
                <>
                    {staticIpFields.map((field, i) => {
                        const elem = getControlForField(field);

                        return (
                            <Grid item key={`wan_static_${field}_${i}`} sx={{ marginBottom: '1.5rem', width: '100%' }}>
                                {elem}
                            </Grid>
                        );
                    })}
                </>
            )}

            <Grid item>
                <br />
            </Grid>

            {dnsFields.map((field, i) => {
                const elem = getControlForField(field);

                return (
                    <Grid item key={`wan_dns_${field}_${i}`} sx={{ marginBottom: '1.5rem', width: '100%' }}>
                        {elem}
                    </Grid>
                );
            })}

            <Grid item sx={{ marginTop: '1rem', marginBottom: '1.5rem', width: '100%' }}>
                {getControlForField({
                    type: 'toggle',
                    disabled: configSaving,
                    label: t('internet-bridge-wan-alias'),
                    getValue: () => {
                        return showBridgeConfig;
                    },
                    setValue: (val: boolean) => {
                        setShowBridgeConfig(val);

                        updateConfig(cloneConfig); //Called here to set the "configChanged" flag
                    },
                })}
                {!showBridgeConfig && (
                    <Typography sx={{ marginTop: '0.25rem' }}>{t('internet-bridge-wan-hint1')}</Typography>
                )}
            </Grid>

            {showBridgeConfig && (
                <Grid item sx={{ marginBottom: '1.5rem', width: '100%' }}>
                    {getControlForField(internetBridgeAliasField)}
                </Grid>
            )}

            <SaveButton
                configValid={configValid}
                configModified={configModified}
                configSaving={configSaving}
                saveConfig={() => {
                    if (showBridgeConfig && internetBridgeAliasField.isValid!()) {
                        cloneConfig.internet_bridge.members[iface] = {
                            mode: 'alias',
                            alias: internetBridgeAliasIp,
                        };
                    } else {
                        cloneConfig.internet_bridge.members[iface] = {
                            mode: 'none',
                            alias: '',
                        };
                    }

                    // FIXME: Passing the clone here seems very broken and incorrect :(
                    saveConfig(cloneConfig);
                }}
            />
            <Typography
                color={'error'}
                sx={{ marginTop: '0.5rem', cursor: 'pointer', userSelect: 'none', textDecoration: 'underline' }}
                onClick={() => {
                    // noinspection RedundantConditionalExpressionJS
                    cloneConfig.interfaces[iface].default = availableNetworkInterfaces.lte !== null ? false : true;
                    cloneConfig.interfaces[iface].mode = 'automatic';

                    cloneConfig.interfaces[iface].ip_config.address = '';
                    cloneConfig.interfaces[iface].ip_config.netmask = '';
                    cloneConfig.interfaces[iface].ip_config.gateway = '';

                    cloneConfig.interfaces[iface].dns.primary = '1.1.1.1';
                    cloneConfig.interfaces[iface].dns.secondary = '';

                    delete cloneConfig.internet_bridge.members[iface];

                    setShowBridgeConfig(false);

                    updateConfig(cloneConfig);
                }}
            >
                {t('reset-to-default')}
            </Typography>
        </Grid>
    );
};
