import React, { FunctionComponent } from 'react';

import { ReactComponent as LanguageIcon } from '../assets/language.svg';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLanguageSetting } from '../hooks/useLanguageSetting';

export const LanguageSelectionButton: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const [lang, setLang] = useLanguageSetting();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button variant="outlined" startIcon={<LanguageIcon />} disableElevation onClick={handleClick}>
                {t(lang)}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        setLang('de');
                        handleClose();
                    }}
                    disableRipple
                >
                    {t('de')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setLang('en');
                        handleClose();
                    }}
                    disableRipple
                >
                    {t('en')}
                </MenuItem>
            </Menu>
        </div>
    );
};
