import React, { FunctionComponent } from 'react';
import { useDeviceOverviewQuery } from '../api/hooks';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PaperContainer from '../components/PaperContainer';

import { sendLogin } from '../api/client';
import { useSessionToken } from '../hooks/useSessionToken';
import { useNavigate } from 'react-router';
import { InfoDialog } from '../components/InfoDialog';

export const LoginPage: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const [sessionToken, setSessionToken] = useSessionToken(); //eslint-disable-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate();
    const { data: deviceOverview } = useDeviceOverviewQuery();

    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [loginError, setLoginError] = React.useState(false);
    const [passwordInfoDialogOpen, setPasswordInfoDialogOpen] = React.useState(false);

    return (
        <PaperContainer>
            <Grid container direction="column">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setLoginError(false);

                        if (password.length > 0) {
                            sendLogin({
                                username: 'admin',
                                password: password,
                            })
                                .then((response) => {
                                    setSessionToken(response.accessToken);

                                    navigate('/status');
                                })
                                .catch((err) => {
                                    setLoginError(true);
                                });
                        }
                    }}
                >
                    <Grid item sx={{ flexGrow: 1 }}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <InputLabel htmlFor="login-password">{t('password')}</InputLabel>
                            <OutlinedInput
                                error={loginError}
                                id="login-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword(!showPassword);
                                            }}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                label={t('password')}
                            />
                            {loginError && (
                                <FormHelperText error id="login-password-error">
                                    {t('invalid-credentials')}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, marginTop: '1rem' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={password.length === 0}
                            sx={{
                                width: '100%',
                                fontWeight: 'bolder',
                                fontSize: '1rem',
                                minHeight: '3rem',
                            }}
                        >
                            {t('login')}
                        </Button>
                    </Grid>
                </form>
                <Grid
                    item
                    sx={{
                        flexGrow: 1,
                        textAlign: 'center',
                        marginTop: '1rem',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{
                            cursor: 'pointer',
                            userSelect: 'none',
                        }}
                        onClick={() => {
                            setPasswordInfoDialogOpen(true);
                        }}
                    >
                        {t('where-find-password')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        flexGrow: 1,
                        textAlign: 'center',
                        marginTop: '1.5rem',
                    }}
                >
                    <Typography variant="subtitle1" sx={{ color: '#c5c5c5' }}>
                        {t('serial-number')}: {deviceOverview?.serialNumber}
                    </Typography>
                </Grid>
            </Grid>
            <InfoDialog
                title={t('default-password')}
                body={t('password-info')}
                dialogOpen={passwordInfoDialogOpen}
                setDialogOpen={(open) => setPasswordInfoDialogOpen(open)}
            />
        </PaperContainer>
    );
};
