import React, { FunctionComponent } from 'react';
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import PaperContainer from '../components/PaperContainer';
import { sendChangePassword } from '../api/client';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ActionFeedbackButton } from '../components/ActionFeedbackButton';

export const ChangePasswordPage: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [currentPassword, setCurrentPassword] = React.useState('');
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState('');
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [newPasswordRepeat, setNewPasswordRepeat] = React.useState('');
    const [showNewPasswordRepeat, setShowNewPasswordRepeat] = React.useState(false);

    const [currentPasswordInvalid, setCurrentPasswordInvalid] = React.useState(false);

    const { newPasswordValid, newPasswordErrorMsg } = React.useMemo(() => {
        setCurrentPasswordInvalid(false);

        let newPasswordValid = true;
        let newPasswordErrorMsg = '';

        if (newPassword.length === 0) {
            newPasswordValid = false;
        } else if (newPassword.length > 0 && newPassword.length < 6) {
            newPasswordErrorMsg = t('password-too-short');
            newPasswordValid = false;
        } else if (newPassword !== newPasswordRepeat) {
            newPasswordErrorMsg = t('passwords-no-match');
            newPasswordValid = false;
        }

        return {
            newPasswordValid,
            newPasswordErrorMsg,
        };
    }, [newPassword, newPasswordRepeat, t]);

    const [passwordChanging, setPasswordChanging] = React.useState(false);

    const onSubmit = React.useCallback(
        (e?: React.FormEvent) => {
            e?.preventDefault();
            setCurrentPasswordInvalid(false);

            setPasswordChanging(true);

            sendChangePassword({
                currentPassword: currentPassword,
                newPassword: newPassword,
            })
                .then(() => {
                    setCurrentPassword('');
                    setNewPassword('');
                    setNewPasswordRepeat('');

                    enqueueSnackbar(t('password-successfully-changed'), {
                        variant: 'success',
                    });
                })
                .catch((err) => {
                    setCurrentPasswordInvalid(true);
                })
                .finally(() => {
                    setPasswordChanging(false);
                });
        },
        [
            setCurrentPasswordInvalid,
            setPasswordChanging,
            currentPassword,
            newPassword,
            setCurrentPassword,
            setNewPassword,
            enqueueSnackbar,
            t,
        ],
    );

    return (
        <PaperContainer>
            <Grid container direction="column">
                <form onSubmit={onSubmit}>
                    <Grid item sx={{ flexGrow: 1 }}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <InputLabel htmlFor="current-password">{t('current-password')}</InputLabel>
                            <OutlinedInput
                                error={currentPasswordInvalid}
                                id="current-password"
                                value={currentPassword}
                                type={showCurrentPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowCurrentPassword(!showCurrentPassword);
                                            }}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setCurrentPasswordInvalid(false);
                                    setCurrentPassword(e.target.value);
                                }}
                                label={t('current-password')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, marginTop: '2rem' }}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <InputLabel htmlFor="new-password">{t('new-password')}</InputLabel>
                            <OutlinedInput
                                value={newPassword}
                                error={!newPasswordValid && newPasswordErrorMsg.length > 0}
                                id="new-password"
                                type={showNewPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowNewPassword(!showNewPassword);
                                            }}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }}
                                label={t('new-password')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, marginTop: '1rem' }}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <InputLabel htmlFor="new-password-repeat">{t('new-password-repeat')}</InputLabel>
                            <OutlinedInput
                                value={newPasswordRepeat}
                                error={!newPasswordValid && newPasswordErrorMsg.length > 0}
                                id="new-password-repeat"
                                type={showNewPasswordRepeat ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowNewPasswordRepeat(!showNewPasswordRepeat);
                                            }}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showNewPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => {
                                    setNewPasswordRepeat(e.target.value);
                                }}
                                label={t('new-password-repeat')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, marginTop: '1rem' }}>
                        <ActionFeedbackButton
                            disabled={!newPasswordValid || passwordChanging}
                            label={t('save-new-password')}
                            actionActive={false}
                            onClick={() => {
                                onSubmit();
                            }}
                        />

                        {!newPasswordValid && newPasswordErrorMsg.length > 0 && (
                            <FormHelperText error>{newPasswordErrorMsg}</FormHelperText>
                        )}
                        {currentPasswordInvalid && <FormHelperText error>{t('old-password-wrong')}</FormHelperText>}
                    </Grid>
                </form>
            </Grid>
        </PaperContainer>
    );
};
