import { LOCAL_STORAGE } from '../const';
import { useLocalStorage } from '@uidotdev/usehooks';
import React from 'react';
import { jwtDecode } from 'jwt-decode';

type SESSION_TOKEN = string | undefined;

export function useSessionToken(): [SESSION_TOKEN, (token: SESSION_TOKEN) => void] {
    const [sessionToken, setSessionToken] = useLocalStorage<string | undefined>(LOCAL_STORAGE.SESSION_TOKEN, undefined);

    React.useEffect(() => {
        // Invalidate on expired or unparsable token
        if (sessionToken && sessionToken?.length > 0) {
            let decodedToken: any;

            try {
                decodedToken = jwtDecode(sessionToken);
            } catch (e) {
                setSessionToken(undefined);
            }

            if (
                decodedToken !== undefined &&
                typeof decodedToken?.exp === 'number' &&
                Date.now() / 1000 >= decodedToken.exp
            ) {
                setSessionToken(undefined);
            }
        }
    }, [sessionToken, setSessionToken]);

    return [sessionToken, setSessionToken];
}
