import { createSvgIcon } from '@mui/material';

// Taken from https://pictogrammers.com/library/mdi/icon/ethernet/ licensed under Apache 2.0
export const EthernetIcon = createSvgIcon(
    <path d="M7,15H9V18H11V15H13V18H15V15H17V18H19V9H15V6H9V9H5V18H7V15M4.38,3H19.63C20.94,3 22,4.06 22,5.38V19.63A2.37,2.37 0 0,1 19.63,22H4.38C3.06,22 2,20.94 2,19.63V5.38C2,4.06 3.06,3 4.38,3Z" />,
    'Ethernet',
);

// Taken from https://pictogrammers.com/library/mdi/icon/ethernet-off/ licensed under Apache 2.0
export const EthernetOffIcon = createSvgIcon(
    <path d="M2.39 1.73L1.11 3L2.32 4.21C2.12 4.56 2 4.95 2 5.38V19.63C2 20.94 3.06 22 4.38 22H19.63C19.78 22 19.92 22 20.07 21.96L20.84 22.73L22.11 21.46L2.39 1.73M15 18H13V15H11V18H9V15H7V18H5V9H7.11L15 16.89V18M9.2 6L6.2 3H19.63C20.94 3 22 4.06 22 5.38V18.8L19 15.8V9H15V6H9.2Z" />,
    'EthernetOff',
);
