import React from 'react';
import './App.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import AppCore from './AppCore';
import { deDE, enUS } from '@mui/material/locale';
import { useLanguageSetting } from './hooks/useLanguageSetting';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { HashRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

const queryClient = new QueryClient();

function App() {
    const [lang] = useLanguageSetting();

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#343a40' },
                secondary: { main: '#eb5032' },
            },
            typography: {
                fontFamily: 'Titillium Web, sans-serif',
            },
            components: {
                MuiToolbar: {
                    styleOverrides: {
                        dense: {
                            height: 120,
                            minHeight: 120,
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            textTransform: 'none',
                        },
                    },
                },
            },
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 500,
                    md: 600,
                    lg: 800,
                    xl: 850,
                },
            },
            shape: {
                borderRadius: 16,
            },
        },
        lang === 'de' ? deDE : enUS,
    );

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
                    <HashRouter>
                        <AppCore />
                    </HashRouter>
                </SnackbarProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
