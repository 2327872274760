import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './res/translations/de.json';
import en from './res/translations/en.json';
import { LOCAL_STORAGE } from './const';

let fallbackLng = 'de';

try {
    const fromLocalStorage = window.localStorage.getItem(LOCAL_STORAGE.LANG);
    if (fromLocalStorage) {
        fallbackLng = JSON.parse(fromLocalStorage);
    }
} catch (e) {
    /* intentional */
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en,
            },
            de: {
                translation: de,
            },
        },
        debug: false,
        fallbackLng: fallbackLng,

        interpolation: {
            escapeValue: false, // react is already safe from xss
        },
    });

export default i18n;
