import {
    AppBar,
    Box,
    Container,
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { ReactComponent as StatusIcon } from './assets/status-icon.svg';
import { ReactComponent as LoginIcon } from './assets/login-icon.svg';
import { ReactComponent as LogoutIcon } from './assets/logout-icon.svg';
import { ReactComponent as SystemInformationIcon } from './assets/system-info-icon.svg';
import { ReactComponent as ChangePasswordIcon } from './assets/restore-password-icon.svg';
import { ReactComponent as NetworkInfoIcon } from './assets/network-info-icon.svg';
import { ReactComponent as NetworkSettingsIcon } from './assets/network-settings-icon.svg';

import { ReactComponent as Logo } from './assets/neoom-logo.svg';
import { LanguageSelectionButton } from './components/LanguageSelectionButton';
import { useTranslation } from 'react-i18next';
import { MenuItem } from './types';
import { useSessionToken } from './hooks/useSessionToken';
import { LoginPage } from './pages/LoginPage';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { StatusPage } from './pages/StatusPage';
import { SystemInformationPage } from './pages/SystemInformationPage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { NetworkInfoPage } from './pages/NetworkInfoPage';
import { NetworkSettingsPage } from './pages/NetworkSettingsPage';

const drawerWidth = 240;
const toolbarHeight = 120;

export default function AppCore() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [sessionToken, setSessionToken] = useSessionToken();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuItems = React.useMemo<Array<MenuItem>>(() => {
        return [
            {
                title: t('login'),
                type: 'item',
                route: '/login',

                visible: sessionToken === undefined,
                icon: <LoginIcon />,

                element: <LoginPage />,
            },
            {
                title: t('status'),
                type: 'item',
                route: '/status',

                visible: sessionToken !== undefined,
                icon: <StatusIcon />,

                element: <StatusPage />,
            },
            {
                title: t('network-info'),
                type: 'item',
                route: '/network_info',

                visible: sessionToken !== undefined,
                icon: <NetworkInfoIcon />,

                element: <NetworkInfoPage />,
            },
            {
                title: t('network-settings'),
                type: 'item',
                route: '/network_settings',

                visible: sessionToken !== undefined,
                icon: <NetworkSettingsIcon />,

                element: <NetworkSettingsPage />,
            },
            {
                title: t('system-info'),
                type: 'item',
                route: '/system_information',

                visible: sessionToken !== undefined,
                icon: <SystemInformationIcon />,

                element: <SystemInformationPage />,
            },
            {
                title: t('change-password'),
                type: 'item',
                route: '/change_password',

                visible: sessionToken !== undefined,
                icon: <ChangePasswordIcon />,

                element: <ChangePasswordPage />,
            },
            {
                title: '',
                type: 'divider',

                visible: sessionToken !== undefined,
            },
            {
                title: t('logout'),
                type: 'item',

                visible: sessionToken !== undefined,
                icon: <LogoutIcon />,

                onClick: () => {
                    setSessionToken(undefined);
                },
            },
        ].filter((route) => route.visible) as Array<MenuItem>;
    }, [sessionToken, setSessionToken, t]);

    const currentItem = useMemo(() => {
        return menuItems.find((item) => item.route === pathname);
    }, [menuItems, pathname]);

    useEffect(() => {
        // Snap to either the login or the home page on invalid routes
        if (sessionToken === undefined) {
            navigate('/login', { replace: true });
        } else if (currentItem === undefined) {
            navigate('/status');
        }
    }, [sessionToken, navigate, currentItem]);

    const currentTitle = useMemo(() => {
        return currentItem?.title ?? '';
    }, [currentItem]);

    const drawer = (
        <div
            style={{
                height: '100%',
                position: 'relative',
            }}
        >
            <div>
                <Toolbar
                    variant="dense"
                    style={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    <Grid container direction="column">
                        <Grid item>
                            <Logo />
                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    font: '500 20px/32px Titillium Web,sans-serif',
                                    userSelect: 'none',
                                }}
                            >
                                BEAAM {t('configuration')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Divider />
                <List>
                    {menuItems.map((item, i) => {
                        switch (item.type) {
                            case 'item': {
                                return (
                                    <ListItemButton
                                        key={`menu_item_${i}`}
                                        selected={item === currentItem}
                                        onClick={() => {
                                            if (item.route) {
                                                navigate(item.route);
                                                setMobileOpen(false);
                                            } else if (item.onClick) {
                                                item.onClick();
                                                setMobileOpen(false);
                                            }
                                        }}
                                    >
                                        <ListItemIcon>{item.icon ?? undefined}</ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                );
                            }
                            case 'divider': {
                                return (
                                    <Divider
                                        key={`menu_item_${i}`}
                                        variant="middle"
                                        component="li"
                                        sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                                    />
                                );
                            }
                            default:
                                return <></>;
                        }
                    })}
                </List>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: '1rem',
                    left: '1rem',
                }}
            >
                <LanguageSelectionButton />
            </div>
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                }}
            >
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Container sx={{ paddingLeft: '8px !important' }}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ fontWeight: 700, fontStyle: 'normal', fontSize: '24px', userSelect: 'none' }}
                        >
                            {currentTitle}
                        </Typography>
                    </Container>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 1,
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    marginTop: `${toolbarHeight}px`,
                }}
            >
                <Routes>
                    {menuItems
                        .filter((item) => item.route !== undefined)
                        .map((item, i) => {
                            return <Route key={`page_${i}`} path={item.route} element={item.element} />;
                        })}

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Box>
        </Box>
    );
}
