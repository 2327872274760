// This is very much not a good nor clean solution. It is good enough for now though.
// In the future, this should be refactored to be a more generic and less hacky form builder.

import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionFeedbackButton } from '../../components/ActionFeedbackButton';

export interface ConfigEditorField {
    type: 'input' | 'toggle';
    label: string;
    disabled?: boolean;
    getValue: () => any;
    setValue: (val: any) => any;
    isValid?: () => any;
    invalidMsg?: string;
}

export function getControlForField(field: ConfigEditorField) {
    let elem;

    switch (field.type) {
        case 'input':
            elem = (
                <TextField
                    label={field.label}
                    fullWidth={true}
                    variant="outlined"
                    value={field.getValue()}
                    disabled={field.disabled}
                    onChange={(e) => {
                        field.setValue(e.target.value);
                    }}
                    error={!field.isValid?.()}
                    helperText={!field.isValid?.() && field.invalidMsg}
                />
            );
            break;
        case 'toggle':
            elem = (
                <FormControlLabel
                    control={
                        <Switch
                            checked={field.getValue()}
                            disabled={field.disabled}
                            color={'secondary'}
                            onChange={(e) => {
                                field.setValue(e.target.checked);
                            }}
                        />
                    }
                    label={field.label}
                    sx={{ userSelect: 'none' }}
                />
            );

            break;
    }

    return elem;
}

export const SaveButton: FunctionComponent<{
    configModified: boolean;
    configValid: boolean;
    configSaving: boolean;
    saveConfig: () => void;
}> = ({ configModified, configValid, configSaving, saveConfig }): React.ReactElement | null => {
    const { t } = useTranslation();

    return (
        <Grid item sx={{ width: '100%' }}>
            <ActionFeedbackButton
                label={t('save')}
                actionActive={configSaving}
                disabled={!configModified || !configValid || configSaving}
                onClick={() => {
                    saveConfig();
                }}
            />
        </Grid>
    );
};
