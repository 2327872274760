import React, { FunctionComponent } from 'react';
import { Box, Card, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import {
    useConnectivityCheckConnectivityQuery,
    useDeviceOverviewQuery,
    useTriggerConnectivityCheckConnectivityMutation,
} from '../api/hooks';
import { ReactComponent as CloudConnectivityIcon } from '../assets/cloud-data-transfer.svg';
import { ReactComponent as BeaamIcon } from '../assets/beaam.svg';
import { ReactComponent as ChecksLoadingIcon } from '../assets/loading-icon.svg';
import { ReactComponent as ChecksSuccessIcon } from '../assets/success-icon.svg';
import { ReactComponent as ChecksFailureIcon } from '../assets/failure-icon.svg';
import { ReactComponent as ChecksWarningIcon } from '../assets/warning-icon.svg';
import { ReactComponent as GreenArrowIcon } from '../assets/arrow-right-green.svg';
import { ReactComponent as RedArrowIcon } from '../assets/arrow-right-red.svg';
import { ReactComponent as OrangeArrowIcon } from '../assets/arrow-right-orange.svg';
import { ReactComponent as CloudConnectIcon } from '../assets/internet.svg';
import { useTranslation } from 'react-i18next';
import { ConnectivityCheckCategoryState, ConnectivityCheckChip } from '../components/ConnectivityCheckChip';
import { ActionFeedbackButton } from '../components/ActionFeedbackButton';

export const ConnectivityCheckView: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const { isPending: connectivityResultPending, data: connectivityResult } = useConnectivityCheckConnectivityQuery();
    const { mutate: triggerConnectivityCheck, isPending: triggerConnectivityCheckExecuting } =
        useTriggerConnectivityCheckConnectivityMutation();
    const { data: deviceOverview } = useDeviceOverviewQuery();

    const ConnectivityCheckResultIcon = React.useMemo(() => {
        if (!connectivityResult) {
            return <ChecksLoadingIcon />;
        }

        switch (connectivityResult.state) {
            case 'unknown':
                return <ChecksWarningIcon />;
            case 'active':
                return <ChecksLoadingIcon />;
            case 'finished':
                if (connectivityResult.succeeded) {
                    return <ChecksSuccessIcon />;
                } else {
                    return <ChecksFailureIcon />;
                }
        }
    }, [connectivityResult]);

    const ConnectivityCheckResultTimestamp = React.useMemo(() => {
        if (connectivityResult?.timestamp) {
            const timestampDate = new Date(connectivityResult.timestamp * 1000);

            return `${timestampDate.toLocaleDateString()} - ${timestampDate.toLocaleTimeString()}`;
        } else {
            return '';
        }
    }, [connectivityResult]);

    const ConnectivityCheckCheckResults = React.useMemo(() => {
        const checkCategories = [
            //The order here controls the render order
            {
                type: 'PORT',
                category: 'cloud',
            },
            {
                type: 'TLS',
                category: 'cloud',
            },
            {
                type: 'URL',
                category: 'cloud',
            },
            {
                type: 'SSH',
                category: 'cloud',
            },

            {
                type: 'NTP_CONNECTIVITY',
                category: 'edge',
            },
            {
                type: 'DNS',
                category: 'edge',
            },
            {
                type: 'NETWORK_OVERLAP',
                category: 'edge',
            },
        ].map((checkCategory: any) => {
            checkCategory.label = t(`connectivity-check-type-${checkCategory.type}`);

            return checkCategory;
        }) as Array<{ type: string; label: string; category: 'cloud' | 'edge' }>;

        return checkCategories.map((category) => {
            const result = connectivityResult?.checks?.[category.type];
            let state = 'unknown';
            if (result) {
                state = result.succeeded ? 'succeeded' : 'failed';
            }

            return {
                type: category.type,
                category: category.category,
                label: category.label,

                state: state as ConnectivityCheckCategoryState,
                checks: result?.checks ?? [],
            };
        });
    }, [connectivityResult, t]);

    const CloudChecks = React.useMemo(() => {
        return ConnectivityCheckCheckResults.filter((c) => c.category === 'cloud');
    }, [ConnectivityCheckCheckResults]);
    const FailedCloudChecks = React.useMemo(() => {
        return CloudChecks.filter((c) => c.state === 'failed');
    }, [CloudChecks]);

    const EdgeChecks = React.useMemo(() => {
        return ConnectivityCheckCheckResults.filter((c) => c.category === 'edge');
    }, [ConnectivityCheckCheckResults]);
    const FailedEdgeChecks = React.useMemo(() => {
        return EdgeChecks.filter((c) => c.state === 'failed');
    }, [EdgeChecks]);

    return (
        <Container style={{ padding: 0, marginTop: '1rem' }}>
            <Card variant="outlined">
                <Box sx={{ p: 2, paddingBottom: 1, paddingTop: 1 }}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" direction="row">
                                <Grid item>
                                    <CloudConnectivityIcon />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        style={{
                                            fontWeight: 700,
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                            userSelect: 'none',
                                        }}
                                    >
                                        {t('connection-status')}
                                    </Typography>
                                </Grid>
                                <Grid item>{ConnectivityCheckResultIcon}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography color="text.secondary" variant="body2" sx={{ userSelect: 'none' }}>
                                {t('last-checked')}:{' '}
                                <span style={{ fontWeight: 'bolder', userSelect: 'all' }}>
                                    {ConnectivityCheckResultTimestamp}
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Card variant="outlined" sx={{ m: '1rem' }}>
                    <Box sx={{ p: 2 }}>
                        <Stack direction="column" justifyContent="space-between" alignItems="center">
                            <CloudConnectIcon />
                            <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                sx={{ fontWeight: 'bolder', userSelect: 'none' }}
                            >
                                {t('neoom-platform-cloud')}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Grid container direction="row" spacing={1} sx={{ marginTop: '1rem' }}>
                            {CloudChecks.map((category, i) => {
                                let arrowIcon;
                                switch (category.state) {
                                    case ConnectivityCheckCategoryState.SUCCEEDED:
                                        arrowIcon = <GreenArrowIcon />;
                                        break;
                                    case ConnectivityCheckCategoryState.FAILED:
                                        arrowIcon = <RedArrowIcon />;
                                        break;
                                    default:
                                        arrowIcon = <OrangeArrowIcon />;
                                }

                                return (
                                    <React.Fragment key={category.type}>
                                        <Grid item>
                                            <ConnectivityCheckChip
                                                type={category.type}
                                                label={category.label}
                                                state={category.state}
                                                checks={category.checks}
                                            />
                                        </Grid>
                                        {i < CloudChecks.length - 1 && (
                                            <Grid item style={{ paddingLeft: '4px', marginRight: '-4px' }}>
                                                {arrowIcon}
                                            </Grid>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                        {FailedCloudChecks.length > 0 && (
                            <ul
                                style={{
                                    marginBottom: 0,
                                    marginRight: '1rem',
                                    marginTop: '1.5rem',
                                    userSelect: 'none',
                                }}
                            >
                                {FailedCloudChecks.map((category, i) => {
                                    return (
                                        <li key={`${category.type}_short_failed`}>
                                            <strong>{t(`connectivity-check-type-${category.type}`)}</strong> -{' '}
                                            {t(`connectivity-check-error-desc-short-${category.type}`)}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </Box>
                </Card>
                <div
                    style={{
                        height: '24px',
                        borderRight: '4px #A9CD66 dotted',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '1px',
                        marginTop: '-1rem',
                    }}
                >
                    &nbsp;
                </div>
                <Card variant="outlined" sx={{ m: '1rem', marginTop: '0' }}>
                    <Box sx={{ p: 2 }}>
                        <Stack direction="column" justifyContent="space-between" alignItems="center">
                            <BeaamIcon />
                            <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                sx={{ fontWeight: 'bolder', userSelect: 'none' }}
                            >
                                {t('beaam')}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ color: '#c5c5c5', marginTop: '-1rem', marginBottom: '0.5rem', userSelect: 'all' }}
                            >
                                {deviceOverview?.serialNumber ?? ''}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Grid container direction="row" spacing={1} sx={{ marginTop: '1rem' }}>
                            {EdgeChecks.map((category, i) => {
                                return (
                                    <Grid item key={category.type}>
                                        <ConnectivityCheckChip
                                            type={category.type}
                                            label={category.label}
                                            state={category.state}
                                            checks={category.checks}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {FailedEdgeChecks.length > 0 && (
                            <ul style={{ marginBottom: 0, marginRight: '1rem', userSelect: 'none' }}>
                                {FailedEdgeChecks.map((category, i) => {
                                    return (
                                        <li key={`${category.type}_short_failed`}>
                                            <strong>{t(`connectivity-check-type-${category.type}`)}</strong> -{' '}
                                            {t(`connectivity-check-error-desc-short-${category.type}`)}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </Box>
                </Card>
                <div
                    style={{
                        marginTop: '0.5rem',
                        padding: '1rem',
                    }}
                >
                    <ActionFeedbackButton
                        label={
                            connectivityResult?.state === 'active' ? t('run-conn-check-progress') : t('run-conn-check')
                        }
                        actionActive={connectivityResult?.state === 'active' || triggerConnectivityCheckExecuting}
                        disabled={
                            connectivityResultPending ||
                            connectivityResult?.state === 'active' ||
                            triggerConnectivityCheckExecuting
                        }
                        onClick={() => {
                            triggerConnectivityCheck();
                        }}
                    />
                </div>
            </Card>
        </Container>
    );
};
