import { LOCAL_STORAGE } from '../const';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';

const isGerman = /^de-/i.test(navigator.language);
type LANG = 'en' | 'de';

export function useLanguageSetting(): [LANG, (lang: LANG) => void] {
    const [lang, setLang] = useLocalStorage<LANG>(LOCAL_STORAGE.LANG, isGerman ? 'de' : 'en');
    const { i18n } = useTranslation();

    return [
        lang,
        (lang: LANG) => {
            i18n.changeLanguage(lang)
                .then(() => {
                    setLang(lang);
                })
                .catch((e) => {
                    /* TODO */
                    console.error(e);
                });
        },
    ];
}
