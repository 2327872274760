import React, { FunctionComponent } from 'react';
import { Box, CircularProgress, Tab, Tabs } from '@mui/material';
import PaperContainer from '../components/PaperContainer';
import { useAvailableNetworkInterfacesQuery, useNetworkConfigMutation, useNetworkConfigQuery } from '../api/hooks';
import { WanInterfaceSettings } from '../views/configForms/WanInterfaceSettings';
import { LanInterfaceSettings } from '../views/configForms/LanInterfaceSettings';
import { NetworkConfig } from '../api/types';
import UnsavedChangesDialog from '../views/configForms/UnsavedChangesDialog';
import { LteInterfaceSettings } from '../views/configForms/LteInterfaceSettings';

export const NetworkSettingsPage: FunctionComponent<{}> = (): React.ReactElement | null => {
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = React.useState(false);
    const [nextSelectedTab, setNextSelectedTab] = React.useState(0);

    const [networkConfig, setNetworkConfig] = React.useState<NetworkConfig | null>(null);
    const [configModified, setConfigModified] = React.useState<boolean>(false);

    const { isPending: availableNetworkInterfacesPending, data: availableNetworkInterfaces } =
        useAvailableNetworkInterfacesQuery();

    const { data: storedNetworkConfig } = useNetworkConfigQuery();
    const { mutate: updateNetworkConfig, isPending: networkConfigUpdating } = useNetworkConfigMutation();

    React.useEffect(() => {
        if (storedNetworkConfig && !configModified && !networkConfigUpdating) {
            setNetworkConfig(structuredClone(storedNetworkConfig));
            setConfigModified(false);
        }
    }, [storedNetworkConfig, configModified, networkConfigUpdating]);

    const ifaceSettingTabs = React.useMemo(() => {
        const out: Array<{ type: 'LAN' | 'WAN' | 'LTE'; iface: string }> = [];

        if (availableNetworkInterfaces?.lan) {
            out.push({ type: 'LAN', iface: availableNetworkInterfaces.lan });
        }
        if (availableNetworkInterfaces?.wan) {
            out.push({ type: 'WAN', iface: availableNetworkInterfaces.wan });
        }

        // After an LTE stick is plugged in, it can take a moment until the system-config gets updated by the firmware
        // Thus, we have to have this additional check here to only render controls if the config backs them
        if (availableNetworkInterfaces?.lte && storedNetworkConfig?.interfaces?.[availableNetworkInterfaces.lte]) {
            out.push({ type: 'LTE', iface: availableNetworkInterfaces.lte });
        }

        return out;
    }, [availableNetworkInterfaces, storedNetworkConfig]);

    if (availableNetworkInterfacesPending || !availableNetworkInterfaces || !networkConfig || !storedNetworkConfig) {
        return (
            <PaperContainer>
                <CircularProgress />
            </PaperContainer>
        );
    }

    return (
        <PaperContainer paperStyle={{ paddingTop: '0' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={(event, newValue) => {
                        if (!configModified) {
                            setSelectedTab(newValue);
                        } else {
                            setNextSelectedTab(newValue);
                            setUnsavedChangesDialogOpen(true);
                        }
                    }}
                    centered
                >
                    {ifaceSettingTabs.map((tabInfo) => {
                        return <Tab label={tabInfo.type} key={`tap_header_${tabInfo.type}`} />;
                    })}
                </Tabs>
            </Box>

            {ifaceSettingTabs.map((tabInfo, i) => {
                let elem;

                switch (tabInfo.type) {
                    case 'LAN':
                        elem = (
                            <LanInterfaceSettings
                                iface={tabInfo.iface}
                                config={networkConfig}
                                configModified={configModified}
                                updateConfig={(newConfig: NetworkConfig) => {
                                    setNetworkConfig(newConfig);
                                    setConfigModified(true);
                                }}
                                saveConfig={() => {
                                    updateNetworkConfig(networkConfig);
                                    setConfigModified(false);
                                }}
                                configSaving={networkConfigUpdating}
                            />
                        );
                        break;
                    case 'WAN':
                        elem = (
                            <WanInterfaceSettings
                                iface={tabInfo.iface}
                                availableNetworkInterfaces={availableNetworkInterfaces}
                                config={networkConfig}
                                configModified={configModified}
                                updateConfig={(newConfig: NetworkConfig) => {
                                    setNetworkConfig(newConfig);
                                    setConfigModified(true);
                                }}
                                saveConfig={(config) => {
                                    // Allow overriding to include bridge settings when saving
                                    if (config) {
                                        setNetworkConfig(config);
                                    }
                                    updateNetworkConfig(config ?? networkConfig);

                                    setConfigModified(false);
                                }}
                                configSaving={networkConfigUpdating}
                            />
                        );
                        break;
                    case 'LTE':
                        elem = (
                            <LteInterfaceSettings
                                iface={tabInfo.iface}
                                config={networkConfig}
                                configModified={configModified}
                                updateConfig={(newConfig: NetworkConfig) => {
                                    setNetworkConfig(newConfig);
                                    setConfigModified(true);
                                }}
                                saveConfig={() => {
                                    updateNetworkConfig(networkConfig);
                                    setConfigModified(false);
                                }}
                                configSaving={networkConfigUpdating}
                            />
                        );
                }

                return (
                    <Box hidden={i !== selectedTab} key={`tab_${tabInfo.type}`} sx={{ paddingTop: 3 }}>
                        {elem}
                    </Box>
                );
            })}

            <UnsavedChangesDialog
                open={unsavedChangesDialogOpen}
                onClose={() => setUnsavedChangesDialogOpen(false)}
                onDiscard={() => {
                    setNetworkConfig(storedNetworkConfig);
                    setConfigModified(false);

                    setSelectedTab(nextSelectedTab);
                }}
            />
        </PaperContainer>
    );
};
