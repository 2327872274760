import axios from 'axios';
import { LOCAL_STORAGE } from '../const';
import {
    AvailableNetworkInterfaces,
    ChangePasswordRequestData,
    ConnectivityCheckResult,
    Device,
    DeviceOverview,
    DHCPLease,
    LoginRequestData,
    LoginResponseData,
    NetworkConfig,
    NetworkState,
    Versions,
} from './types';

export const serverAPI = axios.create({
    baseURL: '../api-internal/',
});

serverAPI.interceptors.request.use((request) => {
    let currentSessionToken = undefined;
    try {
        const fromLocalStorage = window.localStorage.getItem(LOCAL_STORAGE.SESSION_TOKEN);

        if (fromLocalStorage) {
            currentSessionToken = JSON.parse(fromLocalStorage);
        }
    } catch (e) {
        /* intentional */
    }

    if (currentSessionToken) {
        request.headers['Authorization'] = `Bearer ${currentSessionToken}`;
    }

    return request;
});

serverAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const responseURL = error.response?.request?.responseURL ?? '';
        if (error.response?.status === 401 && !responseURL.match(/\/api-internal\/login$/)) {
            window.localStorage.removeItem(LOCAL_STORAGE.SESSION_TOKEN);
            window.location.reload();
        }

        return Promise.reject(error);
    },
);

export const sendLogin = async (loginData: LoginRequestData): Promise<LoginResponseData> => {
    const { status, data } = await serverAPI.post('/login', loginData);

    if (status !== 201) {
        throw new Error('Login failed');
    }

    return data;
};

export const sendChangePassword = async (requestData: ChangePasswordRequestData): Promise<void> => {
    const { data } = await serverAPI.post('/update-password', requestData);

    if (data !== true) {
        throw new Error('Password invalid');
    }
};

export const fetchDeviceOverview = async (): Promise<DeviceOverview> => {
    return serverAPI.get<DeviceOverview>('/device/overview').then(({ data }) => {
        return data;
    });
};

export const fetchDevice = async (): Promise<Device> => {
    return serverAPI.get<Device>('/device').then(({ data }) => {
        return data;
    });
};

export const fetchVersions = async (): Promise<Versions> => {
    return serverAPI.get<Versions>('/device/versions').then(({ data }) => {
        return data;
    });
};

export const fetchAvailableNetworkInterfaces = async (): Promise<AvailableNetworkInterfaces> => {
    return serverAPI.get<AvailableNetworkInterfaces>('/network/interfaces').then(({ data }) => {
        return data;
    });
};

export const fetchNetworkConfig = async (): Promise<NetworkConfig> => {
    return serverAPI.get<NetworkConfig>('/network/config').then(({ data }) => {
        return data;
    });
};

export const sendNetworkConfig = async (networkConfig: NetworkConfig): Promise<void> => {
    return serverAPI.put('/network/config', networkConfig).then(({ status }) => {
        if (status !== 200) {
            throw new Error('Could not update network configuration');
        }
    });
};

export const fetchNetworkState = async (): Promise<NetworkState> => {
    return serverAPI.get<NetworkState>('/network/state').then(({ data }) => {
        return data;
    });
};

export const fetchBridgeDHCPLeases = async (): Promise<Array<DHCPLease>> => {
    return serverAPI.get<Array<DHCPLease>>('/network/bridge/leases').then(({ data }) => {
        return data;
    });
};

export const fetchConnectivityCheckConnectivityResult = async (): Promise<ConnectivityCheckResult> => {
    return serverAPI.get<ConnectivityCheckResult>('/connectivity-check/connectivity').then(({ data }) => {
        return data;
    });
};

export const sendConnectivityCheckConnectivityTrigger = async (): Promise<void> => {
    const { status } = await serverAPI.post('/connectivity-check/connectivity', {});

    if (status !== 201) {
        throw new Error('Failed to trigger connectivity check');
    }
};

export const fetchConnectivityCheckSpeedtestResult = async (): Promise<ConnectivityCheckResult> => {
    return serverAPI.get<ConnectivityCheckResult>('/connectivity-check/speedtest').then(({ data }) => {
        return data;
    });
};

export const sendConnectivityCheckSpeedtestTrigger = async (): Promise<void> => {
    const { status } = await serverAPI.post('/connectivity-check/speedtest', {});

    if (status !== 201) {
        throw new Error('Failed to trigger speedtest');
    }
};
