import React, { FunctionComponent } from 'react';
import { Button, CircularProgress } from '@mui/material';

export const ActionFeedbackButton: FunctionComponent<{
    label: string;
    actionActive: boolean;
    disabled: boolean;
    onClick: () => void;
}> = ({ label, actionActive, disabled, onClick }): React.ReactElement | null => {
    // <span>{label}</span> is a workaround: https://github.com/facebook/react/issues/11538#issuecomment-390386520
    return (
        <Button
            type="submit"
            variant="contained"
            disabled={disabled}
            sx={{
                width: '100%',
                fontWeight: 'bolder',
                fontSize: '1rem',
                minHeight: '3rem',
            }}
            onClick={() => {
                onClick();
            }}
        >
            <span>{label}</span>
            {actionActive && <CircularProgress size={'1rem'} sx={{ position: 'absolute', right: '0.5rem' }} />}
        </Button>
    );
};
