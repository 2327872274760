import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NetworkConfig } from '../../api/types';
import { IPv4_netmask_regex, IPv4_regex } from '../../util';
import { ConfigEditorField, getControlForField, SaveButton } from './formUtils';
import { NetworkIfaceStateCard } from '../../components/NetworkIfaceStateCard';

export const LanInterfaceSettings: FunctionComponent<{
    iface: string;
    config: NetworkConfig;
    configModified: boolean;
    updateConfig: (newConfig: NetworkConfig) => void;
    saveConfig: () => void;
    configSaving: boolean;
}> = ({ iface, config, configModified, updateConfig, saveConfig, configSaving }): React.ReactElement | null => {
    const { t } = useTranslation();
    const cloneConfig = React.useMemo(() => {
        return structuredClone(config);
    }, [config]);

    const fields: Array<ConfigEditorField> = React.useMemo(() => {
        return [
            {
                type: 'input',
                label: t('ip-address'),
                getValue: () => {
                    return config.interfaces[iface].ip_config.address;
                },
                setValue: (val: string) => {
                    cloneConfig.interfaces[iface].ip_config.address = val;

                    updateConfig(cloneConfig);
                },
                isValid: () => {
                    return !!IPv4_regex.exec(config.interfaces[iface].ip_config.address);
                },
                invalidMsg: config.interfaces[iface].ip_config.address === '' ? t('missing-ip') : t('invalid-input'),
            },
            {
                type: 'input',
                label: t('netmask'),
                disabled: true,
                getValue: () => {
                    return config.interfaces[iface].ip_config.netmask;
                },
                setValue: (val: string) => {},
                isValid: () => {
                    return !!IPv4_netmask_regex.exec(config.interfaces[iface].ip_config.netmask);
                },
                invalidMsg: t('invalid-input'),
            },
            {
                type: 'toggle',
                label: t('internet-bridge-dhcp-server'),
                getValue: () => {
                    return config.internet_bridge.dhcp_server.enabled;
                },
                setValue: (val: boolean) => {
                    cloneConfig.internet_bridge.dhcp_server.enabled = val;

                    updateConfig(cloneConfig);
                },
            },
        ].map((e) => {
            e.disabled = e.disabled || configSaving;

            return e;
        }) as Array<ConfigEditorField>;
    }, [t, cloneConfig, updateConfig, config, configSaving, iface]);

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                maxWidth: '550px',
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <Grid item sx={{ width: '100%' }}>
                <NetworkIfaceStateCard iface={iface} />
            </Grid>

            {fields.map((field, i) => {
                const elem = getControlForField(field);

                return (
                    <Grid item key={`lan_${field}_${i}`} sx={{ marginBottom: '1.5rem', width: '100%' }}>
                        {elem}
                    </Grid>
                );
            })}

            <SaveButton
                configValid={fields.reduce((a, c) => {
                    return a && (c.isValid?.() ?? true);
                }, true)}
                configModified={configModified}
                configSaving={configSaving}
                saveConfig={saveConfig}
            />
            <Typography
                color={'error'}
                sx={{ marginTop: '0.5rem', cursor: 'pointer', userSelect: 'none', textDecoration: 'underline' }}
                onClick={() => {
                    //Technically not required here, but it allows for recovery of GWs with invalid legacy configs
                    cloneConfig.interfaces[iface].default = false;
                    cloneConfig.interfaces[iface].mode = 'static';
                    cloneConfig.interfaces[iface].dns.primary = '';
                    cloneConfig.interfaces[iface].dns.secondary = '';

                    cloneConfig.interfaces[iface].ip_config.address = '10.10.10.10';
                    cloneConfig.interfaces[iface].ip_config.netmask = '255.255.255.0';
                    cloneConfig.interfaces[iface].ip_config.gateway = '';

                    cloneConfig.internet_bridge.dhcp_server.enabled = true;

                    updateConfig(cloneConfig);
                }}
            >
                {t('reset-to-default')}
            </Typography>
        </Grid>
    );
};
