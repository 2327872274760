import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface UnsavedChangesDialogProps {
    open: boolean;
    onClose: () => void;
    onDiscard: () => void;
}

const UnsavedChangesDialog: FunctionComponent<UnsavedChangesDialogProps> = ({
    open,
    onClose,
    onDiscard,
}): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('unsaved-changes-dialog-title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('unsaved-changes-dialog-body')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onDiscard();
                        onClose();
                    }}
                    color={'error'}
                >
                    {t('unsaved-changes-dialog-discard')}
                </Button>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >
                    {t('unsaved-changes-dialog-stay')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsavedChangesDialog;
