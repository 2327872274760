import { Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const InfoDialog: FunctionComponent<{
    title: string;
    body: string | React.ReactElement;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
}> = ({ title, body, dialogOpen, setDialogOpen }): React.ReactElement | null => {
    return (
        <BootstrapDialog
            onClose={() => {
                setDialogOpen(false);
            }}
            open={dialogOpen}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
            <IconButton
                onClick={() => {
                    setDialogOpen(false);
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>{body}</DialogContent>
        </BootstrapDialog>
    );
};
