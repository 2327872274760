import React, { FunctionComponent } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import PaperContainer from '../components/PaperContainer';
import { useTranslation } from 'react-i18next';
import { useBridgeDHCPLeaseQuery, useNetworkConfigQuery } from '../api/hooks';

export const NetworkInfoPage: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const { isPending: networkConfigPending, data: networkConfig } = useNetworkConfigQuery();
    const { data: dhcpLeases } = useBridgeDHCPLeaseQuery();

    const dhcpServerEnabled = !!networkConfig?.internet_bridge.dhcp_server.enabled;
    const leases = React.useMemo(() => {
        return dhcpLeases ?? [];
    }, [dhcpLeases]);

    if (networkConfigPending || !networkConfig) {
        return (
            <PaperContainer>
                <CircularProgress />
            </PaperContainer>
        );
    }

    return (
        <PaperContainer>
            <Typography align={'center'}>
                {dhcpServerEnabled ? t('network-info-dhcp-enabled-hint') : t('network-info-dhcp-disabled-hint')}
            </Typography>
            {dhcpServerEnabled && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('ip-address')}</TableCell>
                            <TableCell>{t('vendor')}</TableCell>
                            <TableCell>{t('mac-address')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leases.map((lease) => {
                            return (
                                <TableRow key={lease.mac}>
                                    <TableCell>{lease.address}</TableCell>
                                    <TableCell>{lease.vendor}</TableCell>
                                    <TableCell>{lease.mac}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </PaperContainer>
    );
};
